import React from 'react';

import { Typography } from '@mui/material';

function PageTitle({ title, sx, ...props }) {
  return (
    <Typography
      variant="h5"
      sx={{
        textTransform: 'uppercase',
        borderBottom: '1px solid var(--gray-3)',
        width: 1,
        textAlign: 'center',
        pt: '2rem',
        pb: '1rem',
        fontWeight: 'bold',
        ...sx,
      }}
      {...props}
    >
      {title}
    </Typography>
  );
}

export { PageTitle };
