import {
  format,
  addDays,
  getDay,
  isWithinInterval,
  isSameDay,
  parseISO,
  getDaysInMonth,
} from 'date-fns';

import { convertToDate } from './convertToDate';

const dayMap = {
  dom: 0,
  seg: 1,
  ter: 2,
  qua: 3,
  qui: 4,
  sex: 5,
  sab: 6,
};

export function calculateRecurrence({
  evts,
  startOfCurrentMonth,
  endOfCurrentMonth,
}) {
  const r = {};

  evts.forEach((ev) => {
    const baseDate = ev.created_at.toDate();
    const doctors = ev.doctors;
    const doctorIds = Object.keys(doctors);

    if (
      !isWithinInterval(baseDate, {
        start: startOfCurrentMonth,
        end: endOfCurrentMonth,
      })
    )
      return;

    doctorIds.forEach((doctorId) => {
      const recurrence = doctors[doctorId].recurrence;
      const excludedDates = doctors[doctorId].excluded_dates || [];

      if (recurrence) {
        let currentDate = baseDate;
        let occurrences = 0;
        let count = 0;
        const daysInMonth = getDaysInMonth(currentDate);

        while (true) {
          if (
            isWithinInterval(currentDate, {
              start: startOfCurrentMonth,
              end: endOfCurrentMonth,
            })
          ) {
            const formattedDate = format(currentDate, 'd-M-yyyy');
            if (
              !excludedDates.some((date) =>
                isSameDay(convertToDate(date), currentDate)
              )
            ) {
              if (!r[formattedDate]) r[formattedDate] = {};
              if (!r[formattedDate][doctorId]) r[formattedDate][doctorId] = [];
              r[formattedDate][doctorId].push(ev);
            }
          }

          // Incrementar a data de acordo com a recorrência
          switch (recurrence.repeat_each.type) {
            case 'day':
              currentDate = addDays(currentDate, recurrence.repeat_each.value);
              break;
            case 'week': {
              const currentDay = getDay(currentDate);
              const nextDayIndex = recurrence.repeat_each.repeat.findIndex(
                (day) => dayMap[day] > currentDay
              );

              if (nextDayIndex !== -1) {
                currentDate = addDays(
                  currentDate,
                  dayMap[recurrence.repeat_each.repeat[nextDayIndex]] -
                  currentDay
                );
              } else {
                currentDate = addDays(
                  currentDate,
                  7 - currentDay + dayMap[recurrence.repeat_each.repeat[0]]
                );
              }
              break;
            }
            case 'month':
              if (recurrence.repeat_each.repeat_montly === 0) {
                const start = new Date(currentDate);
                currentDate = new Date(start);
                currentDate.setMonth(start.getMonth() + 1);
              } else if (recurrence.repeat_each.repeat_montly === 1) {
                const start = new Date(currentDate);
                const weekDay = start.getDay();
                const weekOfMonth = Math.floor((start.getDate() - 1) / 7) + 1;

                currentDate = new Date(start);
                currentDate.setMonth(start.getMonth() + 1);
                currentDate.setDate(1);

                let count = 0;
                while (count < weekOfMonth) {
                  if (currentDate.getDay() === weekDay) {
                    count++;
                  }
                  if (count < weekOfMonth) {
                    currentDate.setDate(currentDate.getDate() + 1);
                  }
                }
              }
              break;
            case 'year':
              currentDate = addDays(
                currentDate,
                recurrence.repeat_each.value * 365
              );
              break;
            default:
              break;
          }

          occurrences += 1;
          count += 1;

          if (
            recurrence.ends_on === 'date' &&
            currentDate >
            parseISO(convertToDate(recurrence.ends_on_date).toISOString())
          ) {
            break;
          }
          if (
            recurrence.ends_on === 'occurrences' &&
            occurrences >= recurrence.ends_on_ocurrences
          ) {
            break;
          }
          if (
            recurrence.ends_on === 'never' &&
            currentDate > endOfCurrentMonth
          ) {
            break;
          }

          if (count > daysInMonth) {
            break;
          }
        }
      } else {
        const formattedDate = format(baseDate, 'd-M-yyyy');
        if (
          !excludedDates.some((date) =>
            isSameDay(convertToDate(date), baseDate)
          )
        ) {
          if (!r[formattedDate]) r[formattedDate] = {};
          if (!r[formattedDate][doctorId]) r[formattedDate][doctorId] = [];
          r[formattedDate][doctorId].push(ev);
        }
      }
    });
  });

  return r;
}
