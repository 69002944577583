import React, { createContext, useContext, useState } from 'react';

const SelectedDateContext = createContext();

function SelectedDateProvider({ children }) {
  const [day, setDay] = useState(new Date());

  return (
    <SelectedDateContext.Provider
      value={{
        day,
        setDay,
      }}
    >
      {children}
    </SelectedDateContext.Provider>
  );
}

export const useSelectedDate = () => useContext(SelectedDateContext);

export { SelectedDateProvider };
