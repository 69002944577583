import { isValid, subDays, format } from 'date-fns';
import React, { createContext, useContext } from 'react';

import {
  getDoc,
  doc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  or,
  and,
} from 'firebase/firestore';

import { db } from './auth';
import { registerNotification } from './notifications';

const { customAlphabet } = require('nanoid');

const alphabet = '0123456789';
const nanoid = customAlphabet(alphabet, 6);

const VagasContext = createContext();

function VagasProvider({ children }) {
  const registerVaga = async (
    userId,
    data,
    vagaId = null,
    isEscala = false
  ) => {
    try {
      const userDataRef = vagaId
        ? doc(db, `vagas`, vagaId)
        : doc(collection(db, `vagas`));

      const formatHour = new Date(data.hour);

      const vaga = {
        uid: userId,
        status: true,
        ...data,
        ...(isValid(formatHour)
          ? {
              hour: {
                hour: formatHour.getHours(),
                minute: formatHour.getMinutes(),
              },
            }
          : {}),
      };
      Object.keys(vaga).forEach((k) => {
        if (vaga[k] === undefined) {
          vaga[k] = null;
        }
      });

      vaga.id = userDataRef.id;

      if (!vagaId) {
        vaga.creation_date = serverTimestamp();
        vaga.code = nanoid();
      }

      if (!vaga.code) {
        vaga.code = nanoid();
      }

      await setDoc(userDataRef, vaga, { merge: !vagaId });

      if (!vagaId && !isEscala) {
        await registerNotification(
          {
            readed: false,
            text: `Você criou a vaga para ${data.company.name} com sucesso. Em breve receberá novos candidatos.`,
            title: 'Nova Vaga Criada',
            redirect: '/postar_plantao',
          },
          userId
        );
      }

      return { success: true, vagaId: userDataRef.id };
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
      return { error: true };
    } finally {
      if (!vagaId) {
        try {
          const oldVagas = await getAllVagas({
            maxItems: 100,
            filters: { last: true, order: 1 },
          });

          oldVagas.forEach(async (v) => {
            await deleteVaga(v);
          });
        } catch (err) {
          //
        }
      }
    }
  };

  const registerMinhasVagas = async (userInfo, vagaId, exclude = false) => {
    const copy = { ...userInfo };

    if (!copy.minhas_vagas) copy.minhas_vagas = {};

    if (exclude) {
      delete copy.minhas_vagas[vagaId];
    } else {
      copy.minhas_vagas[vagaId] = vagaId;
    }

    try {
      const userDataRef = doc(db, `users`, userInfo.id);

      await setDoc(userDataRef, copy);
    } catch (err) {
      console.log(err);
    }
  };

  const getVagas = async (userId, options = {}) => {
    const {
      maxItems = 10,
      lastVisible = null,
      gestores = [],
      escalasId = [],
    } = options;
    try {
      const vagasRef = collection(db, 'vagas');
      const pos = [
        and(
          where('uid', 'in', [...gestores, userId]),
          or(
            where('escala.escalaId', 'in', [...escalasId, '']),
            where('uid', '==', userId)
          )
        ),
        orderBy('creation_date', 'desc'),
        limit(maxItems),
      ];

      if (lastVisible) {
        pos.push(startAfter(lastVisible));
      }

      const q = query(vagasRef, ...pos);

      const result = await getDocs(q);

      return result.docs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getAllVagas = async (options = {}) => {
    const { maxItems = 999, lastVisible = null, filters } = options;
    const orders = ['desc', 'asc'];

    try {
      const vagasRef = collection(db, 'vagas');
      let pos = {
        status: where('status', '==', true),
        creation_date: orderBy('creation_date', orders[filters.order]),
        limit: limit(maxItems),
      };

      if (lastVisible) {
        pos.lastVisible = startAfter(lastVisible);
      }

      if (filters.especialidade) {
        pos.especialidade = where(
          'especialidade.ID_ESPECIALIDADE',
          '==',
          filters.especialidade.ID_ESPECIALIDADE
        );
      }

      if (filters.state) {
        pos.state = where('company.state', '==', filters.state.sigla);
      }

      if (filters.last) {
        pos.state = where('creation_date', '<', subDays(new Date(), 90));
      }

      if (filters.code && filters.code.trim().length > 0) {
        pos = { code: where('code', '==', filters.code) };
      }

      const q = query(vagasRef, ...Object.values(pos));

      const result = await getDocs(q);

      return result.docs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getVaga = async (vagaId) => {
    try {
      const docSnap = await getDoc(doc(db, `vagas`, vagaId));

      if (docSnap.exists()) {
        const data = docSnap.data();

        return data;
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  const deleteVaga = async (vaga) => {
    try {
      const vagaId = vaga.id;
      const data = vaga.data();

      if (data.escala) {
        const gdRef = doc(
          db,
          `users/${data.uid}/escala/${data.escala.escalaId}/grades/${data.escala.gradeId}`
        );
        const gd = await getDoc(gdRef);
        const gdData = gd.data();

        const [year, month, day] = format(data.date.toDate(), 'yyyy/M/d').split(
          '/'
        );

        delete gdData.vaga[year][Number(month) - 1][day];

        await setDoc(gdRef, gdData);
      }

      await deleteDoc(doc(db, `vagas`, vagaId));
      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  return (
    <VagasContext.Provider
      value={{
        registerVaga,
        registerMinhasVagas,
        getVagas,
        getAllVagas,
        getVaga,
        deleteVaga,
      }}
    >
      {children}
    </VagasContext.Provider>
  );
}

export const useVagas = () => useContext(VagasContext);

export { VagasProvider };
