import React, { createContext, useContext, useState } from 'react';

import { doc, collection, setDoc, getDocs, getDoc, deleteField, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { db, storage } from './auth';

const TeamContext = createContext();

function TeamProvider({ children }) {
  const [team, setTeam] = useState([]);

  const addMember = async ({ userId, member, blob, memberId }) => {
    try {
      const userRef = memberId
        ? doc(db, `users/${userId}/team`, memberId)
        : doc(collection(db, `users/${userId}/team`));

      let imgUrl = null;
      if (blob) {
        const imgRef = ref(storage, `${userId}/team/${userRef.id}/avatart.jpg`);

        const snap = await uploadBytes(imgRef, blob);

        imgUrl = await getDownloadURL(snap.ref);
      }

      const memberData = { ...member, ...(imgUrl ? { avatar: imgUrl } : { avatar: member.avatar ?? '' }) };

      await setDoc(userRef, memberData, { merge: true });

      return { success: true };
    } catch (error) {
      console.error(error);
      return { error: true };
    }
  };

  const getMembers = async (userId) => {
    try {
      const membersSnap = await getDocs(collection(db, `users/${userId}/team`));

      return membersSnap.docs;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getMember = async ({ userId, memberId }) => {
    try {
      const memberSnap = doc(db, `users/${userId}/team`, memberId);

      const res = await getDoc(memberSnap);

      return { ...res.data(), id: res.id };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const deleteMember = async ({ userId, memberId }) => {
    try {
      const events = await getDocs(collection(db, `users/${userId}/team/${memberId}/events`));

      if (!events.empty) {
        await Promise.all(
          events.docs.map(async (res) => {
            const evData = res.data();

            const evRef = doc(db, `users/${userId}/escala/${evData.escala_id}/events`, res.id);

            await updateDoc(evRef, {
              [`doctors.${memberId}`]: deleteField(),
            });
          })
        );
      }
      const memberSnap = doc(db, `users/${userId}/team`, memberId);

      await deleteDoc(memberSnap);

      return { success: true };
    } catch (error) {
      console.error(error);
      return { error: true };
    }
  };

  return (
    <TeamContext.Provider
      value={{
        addMember,
        getMembers,
        getMember,
        deleteMember,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}

export const useTeam = () => useContext(TeamContext);

export { TeamProvider };
