import React, { createContext, useContext, useState } from 'react';

import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadString,
} from 'firebase/storage';

import { db, storage } from './auth';

const UserContext = createContext();

function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useState(null);

  const getUserInfo = async (userId, team = false, gestor_id) => {
    try {
      const docSnap = await getDoc(
        team
          ? doc(db, `users/${gestor_id}/team`, userId)
          : doc(db, 'users', userId)
      );
      if (docSnap.exists()) {
        let respInfo = {};
        const data = docSnap.data();

        const response = { ...(data || respInfo), id: docSnap.id };

        setUserInfo(response);

        return response;
      }

      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const searchUserByCRM = async (crm) => {
    try {
      const docRef = collection(db, 'users');
      const userQuery = query(docRef, where('crm', '==', crm));

      const result = await getDocs(userQuery);

      return result.docs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const searchUserByEmail = async (email) => {
    try {
      const docRef = collection(db, 'users');
      const userQuery = query(docRef, where('email', '==', email));

      const result = await getDocs(userQuery);

      return { ...result.docs[0].data(), id: result.docs[0].id };
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const changeUserInfo = async (userId, data) => {
    try {
      const docSnap = doc(db, 'users', userId);

      await setDoc(docSnap, data, { merge: false });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const deleteUserInfo = async (userId) => {
    try {
      await deleteDoc(doc(db, `users`, userId));
      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const setAvatar = async (data, userId) => {
    try {
      const avatarRef = ref(storage, `${userId}/avatar.png`);

      const snap = await uploadString(avatarRef, data, 'data_url');

      const avatarUrl = await getDownloadURL(snap.ref);

      await setDoc(
        doc(db, 'users', userId),
        {
          avatar: avatarUrl,
        },
        { merge: true }
      );

      return { success: true };
    } catch (err) {
      return { error: true };
    }
  };

  const deleteStorage = async (userId) => {
    try {
      const rootRef = ref(storage, `${userId}`);
      const root = await listAll(rootRef);

      if (root.items.length > 0) {
        await deleteObject(root.items[0]);
      }

      if (root.prefixes.length > 0) {
        const companyRef = ref(storage, `${userId}/company`);
        const company = await listAll(companyRef);

        if (company.items.length > 0) {
          await Promise.all(
            company.items.map(async (obj) => {
              await deleteObject(obj);
            })
          );
        }
      }

      return { success: true };
    } catch (err) {
      return { error: true };
    }
  };

  const cleanUser = () => {
    setUserInfo(null);
  };

  return (
    <UserContext.Provider
      value={{
        userInfo,
        getUserInfo,
        searchUserByCRM,
        searchUserByEmail,
        deleteUserInfo,
        setAvatar,
        changeUserInfo,
        deleteStorage,
        cleanUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);

export { UserProvider };
