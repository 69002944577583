import './src/assets/styles/global.css';

import React from 'react';

import { setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ptBR as xptBR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  AuthProvider,
  CompanyProvider,
  EscalaApiProvider,
  NotificationsProvider,
  SelectedDateProvider,
  SelectedEscalaProvider,
  SnackbarProvider,
  TeamProvider,
  TermsProvider,
  TopMenuProvider,
  UserProvider,
  VBEProvider,
  VagasProvider,
} from './src/context';

setDefaultOptions({ locale: ptBR });
gsap.registerPlugin(ScrollToPlugin);

if (typeof navigator.serviceWorker !== 'undefined') {
  // navigator.serviceWorker.register('sw.js');
}

export const wrapRootElement = ({ element }) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    localeText={xptBR.components.MuiLocalizationProvider.defaultProps.localeText}
    adapterLocale={ptBR}
  >
    <AuthProvider>
      <TermsProvider>
        <NotificationsProvider>
          <UserProvider>
            <VagasProvider>
              <CompanyProvider>
                <EscalaApiProvider>
                  <VBEProvider>
                    <SelectedDateProvider>
                      <TeamProvider>
                        <TopMenuProvider>
                          <SnackbarProvider>
                            <SelectedEscalaProvider>{element}</SelectedEscalaProvider>
                          </SnackbarProvider>
                        </TopMenuProvider>
                      </TeamProvider>
                    </SelectedDateProvider>
                  </VBEProvider>
                </EscalaApiProvider>
              </CompanyProvider>
            </VagasProvider>
          </UserProvider>
        </NotificationsProvider>
      </TermsProvider>
    </AuthProvider>
  </LocalizationProvider>
);
