import React, { createContext, useContext, useState } from 'react';

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { db } from './auth';

const NotificationsContext = createContext();

export const registerNotification = async (notification, to, notificationId = null) => {
  try {
    const userDataRef = notificationId
      ? doc(db, `users/${to}/notifications`, notificationId)
      : doc(collection(db, `users/${to}/notifications`));

    await setDoc(userDataRef, { ...notification, created_at: serverTimestamp() }, { merge: !notificationId });

    return { sucess: true };
  } catch (err) {
    console.log(err);
    return { error: true };
  }
};

function NotificationsProvider({ children }) {
  const [escalaNotifications, setEscalaNotifications] = useState({});

  const getNotifications = async (userId, filtered = undefined) => {
    try {
      const base = collection(db, `users/${userId}/notifications`);

      const filter = [];

      if (filtered) {
        switch (filtered) {
          case 'length':
            filter.push(where('readed', '==', false));
            break;
          case 'date':
            filter.push(orderBy('created_at', 'desc'));
            break;
          default:
          //
        }
      }

      const results = await getDocs(query(base, ...filter));

      console.log(results);

      return results.docs;
    } catch (err) {
      //
      console.log(err);

      return null;
    }
  };

  const readNotification = async ({ userId, notificationId }) => {
    try {
      const refDoc = doc(db, `users/${userId}/notifications/${notificationId}`);

      await updateDoc(refDoc, { readed: true });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const deleteNotification = async (notificationId, userId) => {
    try {
      await deleteDoc(doc(db, `users/${userId}/notifications`, notificationId));
      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const getEscalaNotifications = async ({ userId, escalaId }) => {
    try {
      const col = collection(db, `users/${userId}/escala/${escalaId}/notifications`);

      const result = await getDocs(col);
      return result.docs;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getEscalaNotificationsUnreaded = async ({ userId, escalaId }) => {
    try {
      const refDoc = collection(db, `users/${userId}/escala/${escalaId}/notifications`);

      const q = query(refDoc, where('readed', '==', false));

      const result = await getDocs(q);

      setEscalaNotifications((not) => ({ ...not, [escalaId]: result.docs }));

      return result.docs;
    } catch (err) {
      return [];
    }
  };

  const readEscalaNotifications = async ({ userId, escalaId, notificationId }) => {
    try {
      const refDoc = doc(db, `users/${userId}/escala/${escalaId}/notifications/${notificationId}`);

      await updateDoc(refDoc, { readed: true });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const deleteEscalaNotification = async ({ userId, notificationId, escalaId }) => {
    try {
      await deleteDoc(doc(db, `users/${userId}/escala/${escalaId}/notifications`, notificationId));
      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const registerEscalaNotification = async ({ userId, escalaId, notification }) => {
    try {
      const docRef = doc(collection(db, `users/${userId}/escala/${escalaId}/notifications`));

      await setDoc(docRef, { created_at: serverTimestamp(), readed: false, ...notification });

      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        escalaNotifications,
        registerNotification,
        getNotifications,
        readNotification,
        deleteNotification,
        getEscalaNotifications,
        readEscalaNotifications,
        deleteEscalaNotification,
        registerEscalaNotification,
        getEscalaNotificationsUnreaded,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => useContext(NotificationsContext);

export { NotificationsProvider };
