import { set } from 'date-fns';
import React, { createContext, useContext, useState } from 'react';

const TopMenuContext = createContext();

function TopMenuProvider({ children }) {
  const [selected, setSelected] = useState('home');

  return (
    <TopMenuContext.Provider
      value={{
        selected,
        setSelected,
      }}
    >
      {children}
    </TopMenuContext.Provider>
  );
}

export const useTopMenu = () => useContext(TopMenuContext);

export { TopMenuProvider };
