import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import { AppBar, Toolbar, Container, BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAuth, useNotifications, useUser, useSelectedEscala } from '@context';
import { useDevice } from '../utils';

function MenuBar({ uri, updateNotifications = false, setUpdateNotifications }) {
  const options = { '/perfil': 0, '/notificacoes': 1, '/': 2, '/sobre': 3 };
  const optionsArr = ['/perfil', '/notificacoes', '/', '/sobre'];
  const [current] = useState(options[uri] ?? 10);
  const [newNotifications, setNewNotifications] = useState(0);

  const auth = useAuth();
  const user = useUser();
  const selectedEscala = useSelectedEscala();

  const notify = useNotifications();
  const { isIphone } = useDevice();

  useEffect(() => {
    if (auth?.user) notifications();
  }, [auth?.user]);

  useEffect(() => {
    if (updateNotifications) {
      setUpdateNotifications(false);

      notifications();
    }
  }, [auth?.user, updateNotifications]);

  const notifications = async () => {
    const result = await notify?.getNotifications(auth?.user.uid, 'length');

    if (result) {
      setNewNotifications(result.length);
    }
  };

  const changeHandler = (e, val) => {
    if (val === 4) {
      auth?.logout();
      selectedEscala?.clearStates();
      user?.cleanUser();
      navigate('/');
      return;
    }

    navigate(optionsArr[val]);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        background: 'transparent',
        boxShadow: 'none',
        ...(isIphone() ? { pb: '1.5rem' } : {}),
      }}
    >
      <Toolbar sx={{ minHeight: { xs: '64px' }, py: 0, alignItems: 'start' }}>
        <Container maxWidth="md" sx={{ height: '100%', px: { xs: 0 } }}>
          <BottomNavigation
            showLabels
            value={current}
            onChange={changeHandler}
            sx={{ borderTop: '1px solid var(--gray-3)', backgroundColor: 'transparent', py: '0.5rem', height: 'auto' }}
          >
            <BottomNavigationAction label="Perfil" icon={<AccountCircleIcon fontSize="large" />} />
            <BottomNavigationAction
              label="Notificações"
              icon={
                <Badge badgeContent={newNotifications} color="error" sx={{ '& .MuiBadge-badge': { top: '2px' } }}>
                  <NotificationsIcon fontSize="large" />
                </Badge>
              }
            />
            <BottomNavigationAction label="Início" icon={<HomeIcon fontSize="large" />} />
            <BottomNavigationAction label="Sobre" icon={<InfoIcon fontSize="large" />} />
            <BottomNavigationAction label="Sair" icon={<LogoutIcon fontSize="large" />} />
          </BottomNavigation>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export { MenuBar };
